.presets-text-container {
  margin-right: 15px;
}

.init-presets-container {
  display: flex;
  font-weight: bold;
  color: #3d0244;
  align-self: center;
  width: min-content;
}
