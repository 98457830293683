/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap'); */
/* @import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@500;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

html {
  font-family: "Red Hat Text", "Arial";
  /* font-weight: bold; */
  background-color: #ecefe8;
  font-size: 20px;
}

.back-button {
  background-color: #3d0244;
  color: white;
  width: 100px;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.back-button-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 50px;
}

.field-page-container {
  display: flex;
}

.team-name-container {
  position: relative;
  background-color: #3d0244;
  height: 70px;
  display: flex;
  justify-content: space-between;
}

.team-name {
  color: white;
  font-size: 36px;
  padding-left: 20px;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  line-height: 70px;
}

.bench-player {
  background-color: #ecefe8;
  border-bottom: 5px solid #abaca6;
  padding: 5px;
}

.line {
  display: flex;
  justify-content: space-around;
}

.player-number-container {
  display: flex;
  justify-content: center;
}

.player-number-and-captain-container {
  display: flex;
  justify-content: space-between;
}

.player-number {
  font-weight: bold;
  font-size: 36px;
  align-self: center;
}

.captain-badge {
  align-self: flex-end;
  margin-left: 10px;
  margin-right: 10px;
  margin: 0 10px 5px 0;
  font-weight: bold;
  font-size: 24px;
}
.gk-line {
  display: flex;
  justify-content: center;
}

.team-logo {
  height: 280px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pl-logo-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pl-logo {
  width: 50px;
  padding-right: 20px;
}

.field {
  width: 95%;
  height: 700px;
  background-image: url("../images/field.jpg");
  background-color: #dedad9;
  background-size: contain;
  background-repeat: round;
  /* border: 5px solid #DEDAD9; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.field-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.player {
  display: flex;
  flex-flow: column;
}

.flag {
  width: 40px;
}

.player-name {
  width: 250px;
  padding: 7px;
  background-color: #edecea;
  text-align: center;
  font-size: 23px;
}

.family-name {
  font-weight: bold;
}

.manager-headline {
  background-color: #3d0244;
  color: white;
  padding: 10px;
  font-weight: bold;
}

.manager-name {
  background-color: #ecefe8;
  color: black;
  padding: 10px;
}

.substitutes-headline {
  background-color: #5bd985;
  color: black;
  padding: 10px;
  border-bottom: 5px solid #abaca6;
  font-weight: bold;
}

.gk-label {
  float: right;
  padding-right: 10px;
}

.left-side {
  width: 20%;
}

.right-side {
  width: 80%;
}

.crest {
  height: 300px;
}
