.player-input-container {
  display: flex;
  margin-bottom: 15px;
  font-weight: bold;
}

.player-input-container input {
  height: 34px;
}

.team-name-input-container input {
  height: 34px;
}

.switch {
  line-height: 30px;
  font-size: 30px;
  padding-left: 5px;
  color: #abaca6;
  cursor: pointer;
}

.switch:hover {
  color: #565752;
}

.switch-selected {
  color: #3d0244;
}

.switch-selected:hover {
  color: #3d0244;
}

.squad-input {
  display: flex;
  justify-content: space-around;
}

.team-name-input-container {
  display: flex;
  padding-bottom: 15px;
  font-weight: bold;
}

.team-name-heading {
  background-color: #3d0244;
  color: white;
  height: 30px;
  padding: 2px;
  width: 180px;
  text-align: right;
}

.manager-name-input {
  background-color: #3d0244;
  color: white;
  height: 30px;
  padding: 2px;
  width: 180px;
  text-align: right;
}

.name-input {
  display: flex;
}

.bench-heading {
  background-color: #3d0244;
  color: white;
  height: 34px;
  margin-top: 15px;
  width: 300px;
  padding: 1px 1px 1px 10px;
  line-height: 34px;
  font-weight: bold;
}
.position {
  background-color: #3d0244;
  color: white;
  /* margin-top: 15px; */
  width: 46px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 2px;
}

input {
  background-color: #abaca6;
  color: #3d0244;
  font-weight: bold;
  font-size: 20px;
  border: none;
  padding: 0 0 0 10px;

  width: 200px;
}

.team-name-input {
  width: 300px;
}

input:focus {
  box-shadow: 0px 0px 0px 2px #3d0244 inset;

  /* remove default glow when selected */
  outline: none;
}

.has-error {
  /* border: 2px solid red; */
  /* box-shadow: 0px 0px 0px 2px red inset; */
}

.has-error input[name="familyName"] {
  box-shadow: 0px 0px 0px 2px red inset;
}

.error-msg {
  font-size: 16px;
  color: red;
}

.third-column {
  display: flex;
  flex-direction: column-reverse;
}

button {
  border: none;
  cursor: pointer;
}

.toolbox-container {
  background-color: #3d0244;
  height: 90px;
  display: flex;
  justify-content: space-between;
}

.team-name {
  position: relative;
}

.add-player-button {
  position: relative;
  background-color: #abaca6;
  width: 46px;
  height: 30px;
  font-size: 20px;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
  font-weight: bold;
}

.add-player-button.disabled {
  opacity: 50%;
  cursor: not-allowed;
}

.add-player-button.disabled:hover .add-player-tooltip {
  visibility: visible;
}

.add-player-tooltip {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  left: 105%;

  position: absolute;
  z-index: 1;
}

.submit-button {
  background-color: #3d0244;
  color: white;
  font-size: 20px;
  padding: 15px;
}

.remove-bench-player-btn {
  padding-left: 5px;
  cursor: pointer;
  opacity: 50%;
}
.remove-bench-player-btn:hover {
  opacity: 100%;
}
